<template>
    <div>
        <div class="title">考勤明细</div>

        <!-- filter -->
        <div class="filter_box">
            <div class="filter_search">
                <!-- select -->
                <el-select
                    class="filter_search_select"
                    v-model="search.count_type"
                    @change="loadData"
                >
                    <el-option
                        v-for="item in count_type_options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>

                <!-- input -->
                <el-input
                    class="filter_search_input"
                    placeholder="请输入关键词"
                    v-model="search.keyword"
                    clearable
                >
                </el-input>
                <div class="custom_button no_select" @click="loadData">
                    搜索
                </div>
                <div
                    class="custom_button no_select"
                    style="margin-left: 10px;"
                    @click="dialogFormVisible = true"
                >
                    同步考勤打卡
                </div>
            </div>
        </div>

        <!-- cards -->
        <div class="cards_box">
            <div class="card" style="width: 450px;">
                <div class="card_item sp">
                    总计<span>{{ statistics.company_people }}</span
                    >人
                </div>
                <el-divider direction="vertical"></el-divider>
                <div class="card_item">
                    <div>正常打卡</div>
                    <div>{{ statistics.punch_9 }}人</div>
                </div>
                <!-- <el-divider direction="vertical"></el-divider>
                <div class="card_item">
                    <div>外勤打卡</div>
                    <div>{{statistics.company_people}}人</div>
                </div> -->
            </div>
            <div class="card" style="width: 770px;">
                <div class="card_item">
                    <div>迟到</div>
                    <div>{{ statistics.punch_1 }}人</div>
                </div>
                <el-divider direction="vertical"></el-divider>
                <div class="card_item">
                    <div>早退</div>
                    <div>{{ statistics.punch_3 }}人</div>
                </div>
                <el-divider direction="vertical"></el-divider>
                <div class="card_item">
                    <div>缺卡</div>
                    <div>{{ statistics.punch_0 }}人</div>
                </div>
                <el-divider direction="vertical"></el-divider>
                <div class="card_item">
                    <div>补卡审核中</div>
                    <div>{{ statistics.punch_5 }}人</div>
                </div>
                <el-divider direction="vertical"></el-divider>
                <div class="card_item">
                    <div>旷工</div>
                    <div>{{ statistics.punch_7 }}人</div>
                </div>
            </div>
            <div class="card" style="width: 300px;">
                <div class="card_item">
                    <div>请假</div>
                    <div>{{ statistics.punch_4 }}人</div>
                </div>
                <el-divider direction="vertical"></el-divider>
                <div class="card_item">
                    <div>外出</div>
                    <div>{{ statistics.punch_6 }}人</div>
                </div>
            </div>
        </div>

        <!-- table -->
        <el-table
            v-if="search.count_type == 'day'"
            :data="tableData"
            :header-cell-style="tableHeaderStyle"
            :cell-style="tableCellStyle"
            style="width: 100%;"
        >
            <el-table-column prop="real_name" label="姓名"> </el-table-column>
            <el-table-column prop="job_number" label="工号"> </el-table-column>
            <el-table-column
                prop="department_data.department_name"
                label="部门"
            >
            </el-table-column>
            <el-table-column prop="department_data.station_name" label="岗位">
            </el-table-column>
            <el-table-column prop="today_status" label="日出勤结果">
            </el-table-column>
            <el-table-column prop="do_time" label="日出勤时长">
            </el-table-column>
            <el-table-column prop="first_punch_time" label="最早上班时间">
            </el-table-column>
            <el-table-column prop="last_punch_time" label="最晚下班时间">
            </el-table-column>
            <!-- <el-table-column label="操作">
                <div class="operations">
                    <div>查看</div>
                    <div>导出</div>
                </div>
            </el-table-column> -->
        </el-table>
        <el-table
            v-if="search.count_type == 'month'"
            :data="tableData"
            :header-cell-style="tableHeaderStyle"
            :cell-style="tableCellStyle"
            style="width: 100%;"
        >
            <el-table-column prop="real_name" label="姓名"> </el-table-column>
            <el-table-column prop="job_number" label="工号"> </el-table-column>
            <el-table-column
                prop="department_data.department_name"
                label="部门"
            >
            </el-table-column>
            <el-table-column prop="department_data.station_name" label="岗位">
            </el-table-column>
            <el-table-column prop="have_to_day" label="需出勤天数">
            </el-table-column>
            <el-table-column prop="do_day" label="实际出勤天数">
            </el-table-column>
            <!-- <el-table-column label="操作">
                <div class="operations">
                    <div>查看</div>
                    <div>导出</div>
                </div>
            </el-table-column> -->
        </el-table>

        <el-dialog
            title="添加合同"
            width="40%"
            :visible.sync="dialogFormVisible"
        >
            <el-form :model="form" ref="form" :rules="rules">
                <el-form-item label="开始时间" prop="start_time">
                    <el-date-picker
                        v-model="form.start_time"
                        type="date"
                        value-format="yyyy-MM-dd"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间" prop="end_time">
                    <el-date-picker
                        v-model="form.end_time"
                        type="date"
                        value-format="yyyy-MM-dd"
                    ></el-date-picker>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="syncPunchLog"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    data() {
        return {
            count_type_options: [
                {
                    value: "day",
                    label: "按日",
                },
                {
                    value: "month",
                    label: "按月",
                },
            ],
            search: {
                keyword: "",
                count_type: "month",
            },
            tableData: [],
            statistics: {},

            dialogFormVisible: false,
            form: {
                start_time: "",
                end_time: this.$options.filters.formatTime(
                    new Date(),
                    "yyyy-MM-dd"
                ),
            },
            rules: {
                start_time: [
                    {
                        required: true,
                        message: "请选择开始时间",
                        trigger: "blur",
                    },
                ],
                end_time: [
                    {
                        required: true,
                        message: "请选择结束时间",
                        trigger: "blur",
                    },
                ],
            },

            tableHeaderStyle: {
                backgroundColor: "#F5F8FA",
                textAlign: "center",
            },
            tableCellStyle: {
                textAlign: "center",
            },
        };
    },
    created() {
        this.loadData();
    },
    methods: {
        ...mapActions("attendance", [
            "getAttendanceDetailList",
            "getSyncPunchLog",
        ]),

        loadData() {
            this.getAttendanceDetailList({
                count_type: this.search.count_type,
                keyword: this.search.keyword,
            })
                .then((res) => {
                    console.log(res);
                    this.tableData = res.data.data.user_list;
                    this.statistics = res.data.data.all_count;
                })
                .catch((e) => {
                    console.error(e);
                });
        },

        syncPunchLog() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    let loading = this.$loading();
                    console.log(this.form);
                    this.dialogFormVisible = false;
                    this.getSyncPunchLog(this.form)
                        .then((res) => {
                            console.log(res);
                            loading.close();
                            this.loadData();
                            this.$message.success(res.data.msg);
                        })
                        .catch((e) => {
                            console.error(e);
                            loading.close();
                            this.$message.error(e.msg);
                        });
                } else {
                    this.$notify.error({
                        title: "提交失败",
                        message: "请先完整填写表单",
                    });
                    return false;
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.title {
    font-size: 14px;
    font-weight: bold;
    color: #1c2438;
}

.filter_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0;

    .filter_search {
        display: flex;
        align-items: center;

        .filter_search_select {
            width: 80px;
            height: 36px;
            margin-right: 10px;

            ::v-deep .el-input__inner {
                height: 36px;
            }

            ::v-deep .el-input__icon {
                line-height: 36px;
            }
        }

        .filter_search_input {
            width: 350px;
            height: 36px;
            margin-right: 10px;

            ::v-deep .el-input__inner {
                height: 36px;
            }
        }
    }

    .filter_btns {
        display: flex;
        align-items: center;

        > div:not(:first-child) {
            margin-left: 20px;
        }
    }
}

.cards_box {
    display: flex;
    height: 100px;
    background-color: #fff;
    margin-bottom: 40px;
    justify-content: space-between;

    .card {
        display: flex;
        align-items: center;
        box-shadow: 0px 6px 30px 0px rgba(223, 227, 236, 0.4);
        border-radius: 4px;

        .el-divider {
            height: 70px;
        }

        .card_item {
            flex: 1;
            width: max-content;
            height: 70px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: #495060;
            font-size: 14px;

            & > div:nth-child(1) {
                margin-bottom: 15px;
            }

            &.sp {
                flex-direction: row;
                color: #4db9d5;
                font-size: 16px;
                position: relative;
                top: 3px;

                span {
                    font-size: 30px;
                    position: relative;
                    bottom: 3px;
                }
            }
        }
    }
}

.operations {
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
        font-size: 14px;
        color: #406bff;
        cursor: pointer;

        &:not(:last-child) {
            margin-right: 13px;
        }
    }
}
</style>
